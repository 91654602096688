import axios, { AxiosProgressEvent } from 'axios';
import moment from 'moment';

import { IConsultationPendingEvent } from '../screens/BookOneToOneConsultation/types';
import { IRenewMango } from '../screens/Feed/components/RenewMangoes/types';
import {
  CommentInsertResponse,
  CommentResponse,
  DeeplinkCommentResponse,
  ReplyResponse,
} from '../types/commentTypes';
import {
  ICreatorWebinarsRequest,
  ILikedByUser,
  ILikedByUsersRequest,
  IMention,
  IPollResponse,
  IReportRequest,
  ITags,
  IWebinarResponse,
  IWebinarResponseV5,
} from '../types/feedTypes';
import API from './';

export default abstract class FeedAPI {
  static createPost = (
    postData: any,
    onUploadProgress: (event: AxiosProgressEvent) => void,
  ) => {
    const headers = {
      ...axios.defaults.headers.common,
      'Content-Type': 'multipart/form-data',
    };
    return API.post<any>('/create-post', postData, {
      headers,
      onUploadProgress,
    });
  };

  static editPost = (
    id: string,
    mangoes: any[],
    caption: string,
    scheduleTime: Date | string | undefined,
    thumbnail: string | null,
    tags?: string[],
  ) => {
    let date = moment(Date.now()).toISOString();
    if (scheduleTime) {
      date = moment(scheduleTime).toISOString();
    }

    let data: any = {
      caption,
      mangoArr: mangoes.map((mango) => mango._id).join(','),
      scheduleTime: date,
      thumbnail: thumbnail || '',
    };

    if (tags && tags.length > 0) {
      data = {
        ...data,
        tags: tags.join(','),
      };
    }

    return API.post(`/edit-post/${id}`, data);
  };

  static getPosts = (
    page: number,
    creatorId: string,
    contentType: any[] = [],
    selectedMango: any[] = [],
    sort = 'asc',
    tags: string[] = [],
    search = '',
    showUserPosts: boolean = false,
  ) => {
    const contentTypeString = contentType.length
      ? `&contentType[]=${contentType.join('&contentType[]=')}`
      : '';

    const selectedMangoString = selectedMango.length
      ? `&mango[]=${selectedMango.join('&mango[]=')}`
      : '';

    const tagsString = tags.length ? `&tags[]=${tags.join('&tags[]=')}` : '';

    const creatorIdString = creatorId
      ? (showUserPosts && `fan=${creatorId}&`) || `creator=${creatorId}&`
      : '';

    let url = `/fetch-post-for-feed?${creatorIdString}page=${page}${contentTypeString}${selectedMangoString}${tagsString}&sort=${sort}`;

    if (search && search !== '') url += `&search=${search}`;

    return API.get<any>(url);
  };

  static newActivityApi = (userId?: string | null) => {
    let requiredUserQuery = '';
    if (userId) requiredUserQuery = `?requiredUser=${userId}`;
    return API.post<any>(`/v5/new-activity-api${requiredUserQuery}`);
  };

  static getUpcomingVideoCalls = (
    creator: string | null,
    limit: number | null,
  ) => {
    let data: ICreatorWebinarsRequest = {
      status: 'upcoming',
    };
    if (creator)
      data = {
        ...data,
        creator,
      };
    if (limit) data = { ...data, limit };
    return API.post<IWebinarResponse>(
      `/v3/get-all-videocall-slot-by-date`,
      data,
    );
  };

  static getCreatorWebinars = async (data: ICreatorWebinarsRequest) => {
    return API.post<IWebinarResponse>(
      '/v3/get-all-videocall-slot-by-date',
      data,
    );
  };

  static getCreatorWebinarsV5 = async (data: ICreatorWebinarsRequest) => {
    return API.post<IWebinarResponseV5>(
      '/v5/get-all-videocall-slot-by-date',
      data,
    );
  };

  // check workshop attendance status
  static checkWorkshopAttendanceStatus = (workshopId: string) => {
    return API.get<boolean>(`/get-attendance-status/${workshopId}`);
  };

  static addToCalendar = (webinarId: string) =>
    API.get<string>(`/get-calendar-link-for-webinar/${webinarId}`);

  static likeUnlikePost = (id: string) =>
    API.post<any>(`/like-or-unlike-post`, { postId: id });

  static deleteVideoCall = <T = { slotId: string[] }>(body: T) =>
    API.post('/v2/cancel-video-slot', body);

  static votePoll = (pollId: string, optionId: string) =>
    API.post<any>(`/polls/vote`, {
      pollId,
      optionId,
    });

  static undoVotePoll = (pollId: string) =>
    API.delete<any>(`/polls/vote`, {
      data: {
        pollId,
      },
    });

  static getPostById = (id: string) => API.get(`/v2/fetch-post-by-id/${id}`);

  static deletePost = (postId: string) =>
    API.post(`/delete-post/${postId}`, {});

  static updatecommentLike = (commentId: string) =>
    API.post('/like-or-unlike-comment', { commentId });

  static getCommentsForDeeplink = (comment: string) =>
    API.post<DeeplinkCommentResponse>('/fetch-comments-from-deeplink', {
      comment,
    });

  static getCommentsByPost = (
    id: string,
    direction: 'old' | 'new',
    lastComment?: string,
  ) =>
    API.post<CommentResponse>(`/v2/fetch-comments-for-post`, {
      post: id,
      lastComment,
      direction,
    });

  static getCommentsForPostById = (
    postId: string,
    lastComment?: string,
    limit?: number,
  ) => {
    let url = `/v2/fetch-comments-for-post?post=${postId}&limit=${limit || 5}`;
    if (lastComment) {
      url += `&lastComment=${lastComment}`;
    }
    return API.get<CommentResponse>(url);
  };

  static getRepliesForComment = (
    comment: string,
    direction: 'old' | 'new',
    lastReply?: string,
  ) =>
    API.post<ReplyResponse>('/fetch-replies-for-comment', {
      comment,
      lastReply,
      direction,
    });

  static createComment = (commentData: {
    postId: string;
    commentId?: string;
    text: string;
    commentRef?: string;
  }) => API.post<CommentInsertResponse>('/create-or-edit-comment', commentData);

  static deleteComment = (commentId: string) =>
    API.post(`/delete-comment/${commentId}`);

  static searchMentionedUser = (searchTerm: string, postId: string) =>
    API.get<IMention[]>(
      `/get-users-for-comment?term=${searchTerm}&postId=${postId}`,
    );

  static getUnreadNotificationsCount = () =>
    API.get('/get-all-unread-notificationscount');

  static reportMessage = (data: IReportRequest) =>
    API.post<any>('/reports', data);

  static toggleEnableOrDisableComments = (postId: string, value: boolean) =>
    API.post<any>(`/toggle-comment-for-post/${postId}`, {
      toggle: value ? 'disable' : 'enable',
    });

  static togglePinPost = (postId: string) =>
    API.post(`/toggle-pin-post/${postId}`);

  static getTags = () => API.get<ITags[]>(`/tags`);

  static getRenewMangoes = (creatorId?: string) => {
    let url = '/renew-subscription-list';
    if (creatorId) {
      url += `?creatorId=${creatorId}`;
    }
    return API.get<IRenewMango[]>(url);
  };

  // 1-1 consultation
  static fetchPendingSlots = () =>
    API.get<IConsultationPendingEvent[]>('/pending-bookings');

  static bookConsultationSlot = (
    eventId: string,
    slotId: string,
    date: string,
  ) =>
    API.post<{
      videocall: string;
    }>(`/bookings/${eventId}`, {
      slotId,
      date,
    });

  static cancelConsultationBooking = (bookingIds: string[]) =>
    API.post<any>('/bookings/cancel', {
      bookingIds,
    });

  static markAttendance = (meetingId: string) =>
    API.post<any>('/give-attendance', {
      webinar: meetingId,
    });

  static getVideoUploadPermissions = () =>
    API.get('/check-video-upload-permission');

  static createPoll = (
    question: string,
    options: string[],
    mangoArr: string[],
    startAt: Date,
    endAt: Date,
    tags?: string[],
  ) =>
    API.post<IPollResponse>('/polls', {
      question,
      pollOptions: options.map((option, index) => ({
        optionType: 'text',
        optionContent: option,
        optionNumber: index + 1,
      })),
      startAt,
      endAt,
      mangoArr,
      tags,
    });

  static getAllLikedByUsers = (data: ILikedByUsersRequest) => {
    const { page, limit, postId, commentId, search } = data;

    const query = new URLSearchParams({
      page: page.toString(),
      limit: limit.toString(),
      ...(postId && { postId }),
      ...(commentId && { commentId }),
      ...(search && { search }),
    });

    return API.get<ILikedByUser[]>(
      `/fetch-all-likes-details${query ? `?${query}` : ''}`,
    );
  };
}
