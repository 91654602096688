/* eslint-disable prefer-destructuring */
/* eslint-disable @typescript-eslint/no-explicit-any */
import { message } from 'antd';

import { ErrorStatusMessages } from './constants';

export interface IAppErrorParams {
  duration?: number;
  errorMsg?: string;
}

export type AppErrorParamsType = number | string | IAppErrorParams;

export const DEFAULT_ERROR_MESSAGE =
  'Something went wrong. Please try again later.';
export const DEFAULT_ERROR_MESSAGE_DURATION = 3;

const getErrorMessage = (
  messages: any[],
  code: number,
  fallbackErrorMsg?: string,
): string => {
  return (
    messages.find((msg) => msg && typeof msg === 'string') ||
    ErrorStatusMessages[code as keyof typeof ErrorStatusMessages] ||
    fallbackErrorMsg ||
    DEFAULT_ERROR_MESSAGE
  );
};

/**
 * This function is used to show error message to the user where custom error message should be passed as a fallback message for the user's better understanding if the API fails.
 * @param error - error object from the API response or the error message
 * @param params - duration or custom error message or both in a object (type: number | string | {
  duration?: number;
  errorMsg?: string;
})
 * @returns void
 * @example
 * showAppError('Something went wrong');
 * showAppError('Something went wrong', 3);
 * showAppError('Something went wrong', 'Custom error message'); 
 */

const showAppError = (error: any, params?: AppErrorParamsType) => {
  // error = {response, status, data}

  // console.log('response:', error.response);
  // console.log('status:', error.status);
  // console.log('data:', error.data);

  let errorMsg = DEFAULT_ERROR_MESSAGE;
  const fallbackErrorMsg =
    typeof params === 'object' && params.errorMsg
      ? params.errorMsg
      : (typeof params === 'string' && params) || '';

  // if error = {code, type, result, message}
  // Handle 0 as it is the success code (OK) of our backend
  if (error?.code >= 400 || error?.code !== 0) {
    errorMsg = getErrorMessage(
      [
        error?.result?.message,
        error?.result,
        // error.message,
      ],
      error.code,
      fallbackErrorMsg,
    );
  }

  console.log('errorResp:', error?.response || error?.result || error);

  // if default error
  if (error?.status >= 400) {
    const errorResp = error.response;

    errorMsg = getErrorMessage(
      [
        // errorResp?.data?.error,
        errorResp?.data?.result?.message,
        errorResp?.data?.result,
        // errorResp?.data?.message,
        errorResp?.result?.message,
        errorResp?.result,
        // errorResp?.message,
      ],
      error.status,
      fallbackErrorMsg,
    );
  }

  switch (errorMsg) {
    case 'Unauthorized':
      errorMsg = ErrorStatusMessages[401];
      break;
    case 'Too Many Requests':
    case 'Too many requests':
    case 'Too many requests, please try again later.':
      errorMsg = ErrorStatusMessages[429];
      break;
    case 'Internal Server Error':
      errorMsg = ErrorStatusMessages[500];
      break;
    default:
      break;
  }

  const errorStatusCode = (error?.code ||
    error?.status) as keyof typeof ErrorStatusMessages;

  console.log('errorStatusCode:', errorStatusCode);

  // Show generalised error message based on specific error status codes
  if ([401, 403, 404, 429].includes(errorStatusCode)) {
    errorMsg = ErrorStatusMessages[errorStatusCode];
  }

  const finalErrorMsg = typeof error === 'string' ? error : errorMsg;

  console.log('errorMsg:', finalErrorMsg);

  // show error message to the user
  message.error({
    content: finalErrorMsg,
    duration:
      typeof params === 'object' && params.duration
        ? params.duration
        : (typeof params === 'number' && params) ||
          DEFAULT_ERROR_MESSAGE_DURATION,
    key: 'errorAlert',
    type: 'error',
  });
};

export default showAppError;
